const defaultEmits = ['update:modelValue'];

interface DefaultProps {
    containerClass?: string;
    formErrors?: object;
    helpText?: string;
    label?: string;
    labelClass?: string;
    inputClass?: string;
    name: string;
    submitting?: boolean;
    type?: string;
    modelValue?: number | string | null;
}

const defaultPropsDefaults = {
    containerClass: undefined,
    formErrors: () => ({}),
    helpText: undefined,
    label: undefined,
    labelClass: '',
    inputClass: 'floating-label-white',
    submitting: false,
    type: 'text',
    modelValue: '',
};

const useInput = (
    props: any,
    emit: (event: string, ...args: any[]) => void,
) => {
    const id = computed(() => `input-${props.name}`);

    const value = ref<string | number | null>(null);

    const errors = computed(() => {
        return props.formErrors[props.name] || [];
    });

    const isErrored = computed(() => errors.value.length > 0);

    watch(
        () => props.modelValue,
        (val) => {
            value.value = val;
        },
        { immediate: true },
    );

    watch(value, (val) => {
        emit('update:modelValue', val);
    });

    return {
        value,
        errors,
        isErrored,
        id,
    };
};

export type { DefaultProps };
export { defaultEmits, defaultPropsDefaults, useInput };
